@media (max-width:640px) {
    .service-info-box{
        display: flex;
        flex-direction: column;
        gap: 20px;
        
    }

}

.right-img{
    width: 100%;
    height: 300px;
}
.main-bg{
    background-color: #F9FAF0;
    
   
    
   
}