.logi-inner-con {
    background-image: url('../../public/Images/logihero.jpg');
    background-position:center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.service-sec{
    background-color: #F9FAF0;
}

