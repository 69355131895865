.input-con input{
    border: 1px solid black;
    background-color: inherit;
    margin-bottom: 20px;
}
.input-con select{
    border: 1px solid black;
    background-color: inherit;
}
.form-con{
    background-image: url('../../public/Images/logihero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}