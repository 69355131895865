@media (max-width:1280px) {
    .truck-img{
        width: 50%;
    }
    .second-image img{
        width: 100%;
    }
}
.hero-con{
    background-image: url('../../public/Images/hero.svg');
    background-position: center;
    background-size: cover;
}