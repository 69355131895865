@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
  --primary-green: #2F9B1E;
  ;
  --text-green: #23611A;

  --primary-light:#709dff;
  --primary-font:Instrument sans;
  --main-font:Proza Libre;
  --main-bg:[rgb(248,250,240)];
}


@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply font-[Roboto];
  }
  li{
    @apply p-4 text-sm
  }
  
  h2{
    @apply text-xl font-bold
  }
}
*{
  padding: 0;
  margin: 0;
}

section{
  padding: 0 40px;
}


.header-con{
  border-bottom: 0.1px solid rgb(85, 84, 84);
  padding: 10px 0;
  
}
body{
  font-family: var(--main-font);
}

.swiper-button-next,
.swiper-button-prev {
  color: black !important; /* Change arrow color to black */
  font-size: 20px; /* Optional: Adjust arrow size */
  opacity: 0.8; /* Optional: Slight transparency */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Hover effect for navigation buttons */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: black !important; /* Keep it black */
  transform: scale(1.1); /* Slight enlargement on hover */
  opacity: 1; /* Fully opaque on hover */
}





@media (	max-width:800px) {

  section{
    padding: 0 20px;
  }
}